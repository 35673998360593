<template>
  <el-dialog title="审核" :visible.sync="examineDlilogVisible" width="30%" :before-close="handleClose">
    <div style="margin-left: 30px">
      <el-form :model="ruleForm" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <el-form-item label="审核结果" prop="region">
          <el-select style="width: 300px" v-model="ruleForm.auditState" placeholder="请选择">
            <el-option label="审核通过" :value="2"></el-option>
            <el-option label="审核不通过" :value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="审核意见" prop="name">
          <el-input style="width: 300px" type="textarea" v-model="ruleForm.remarks"></el-input>
        </el-form-item>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="handleClose">取 消</el-button>
      <el-button type="primary" @click="operate">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { operate } from '@/api/pensionService'
export default {
  name: 'examineDlilog',
  props: {
    examineDlilogVisible: {
      type: Boolean,
      default: false
    },
    id: Number,
    type: Number
  },
  data() {
    return {
      ruleForm: {
        auditState: '',
        remarks: '',
        id: this.id,
        type: this.type
      },
    }
  },
  methods: {
    async operate() {
      const res = await operate(this.ruleForm)
      this.$message.success(res.message)
      this.$emit('update:examineDlilogVisible', false)
      this.$router.push('/homeCasedCareServices/applicationList')
    },
    handleClose() {
      this.$emit('update:examineDlilogVisible', false)
    }
  }
}
</script>

<style scoped>
</style>
